<template>
  <div v-if="rejectDetails.denominationCounts"  class="bg-white shadow-lg rounded-md px-4 mb-60">
        <div class="grid grid-cols-12 text-left self-center">
          <div :class="`${largeOrangeText} col-span-12 py-3`">Original Values</div>
          <div class="flex col-span-12 divide-x">
            <DenominationValueCell
                v-for="(value, index) in getDispenserDenominationsCashOnly"
                :key="`entered-${index}`"
                :label="getLabel(value)"
                :value="getAmountForDenomination(value)"
                :class="`flex-1 ${value.denomination.toLowerCase()}-original-box`"
            />
            <DenominationValueCell
                label="Total"
                :value="getTotal"
                class="flex-1 total-original-box"
            />
          </div>
        </div>
      <div class="grid grid-cols-12 text-left self-center">
        <div :class="`${largeOrangeText} col-span-12 py-3`">New Values</div>
        <div class="flex col-span-12 divide-x">
          <DenominationValueCell
              v-for="(value, index) in getDispenserDenominationsCashOnly"
              :key="`entered-${index}`"
              :label="getLabel(value)"
              :value="currencyFormat(0)"
              class="flex-1"
              :class="`flex-1 ${value.denomination.toLowerCase()}-new-box`"
          />
          <DenominationValueCell
              label="Total"
              :value="currencyFormat(0)"
              class="flex-1 total-new-box"
          />
        </div>
      </div>
    <div class="text-left border-b py-4 flex flex-row">
      <div :class="`${largeOrangeText}`">Error Code:</div>
      <div class="text-md mt-0.5 ml-5 cashAcceptDetailStatusCode">{{ rejectDetails.statusCode || "None" }}</div>
    </div>
    <div class="text-left py-4 flex flex-row">
      <div :class="`${largeOrangeText}`">Error Message:</div>
      <div class="text-md mt-0.5 ml-5 cashAcceptDetailErrorMessage">{{ rejectDetails.description || "None" }}</div>
    </div>
  </div>
</template>

<script>
import TransactionLogDetail from "../../mixin/TransactionLogDetail";
import currency from "currency.js";
import DenominationValueCell from "../Helpers/DenominationValueCell";
export default {
  name: "DispenserRejectedDetailsCard",
  props: {
    rejectDetails: {
      type: Object,
      required: true,
    }
  },
  components: {DenominationValueCell},
  mixins: [TransactionLogDetail],
  computed: {
    largeOrangeText() {
      return "text-orange font-medium text-xl"
    },
    getTotal() {
      return currency(this.rejectDetails.total || 0).format();
    },
    getDenominationCounts() {
      return this.rejectDetails.denominationCounts
    }
  },
  methods: {
    getLabel( bill ) {
      return bill.label;
    },
    getAmountForDenomination( bill ) {
      return  currency((this.getDenominationCounts[bill.denomination] || 0) * bill.quantityMultiplier).format();
    },
  }
}
</script>