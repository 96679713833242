<template>
  <div class="flex justify-between content-center p-2 border-b border-t">
    <span class="font-bold">{{label}}</span>
    <span class="text-gray-500">{{value}}</span>
  </div>
</template>

<script>
export default {
  name: "DenominationValueCell",
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  }
}
</script>

<style></style>