<template>
    <PageTemplate>
      <template slot="nav">
        <BasicNav>
          <template slot="nav-left">
            <div class="flex justify-start">
              <BackButton @click="goBack"></BackButton>
            </div>
          </template>
          <template slot="nav-center">
            <div class="flex justify-center">
              <h1 class="text-3xl whitespace-nowrap">{{resetRejectedTitle}}</h1>
            </div>
          </template>
        </BasicNav>
      </template>
      <template slot="body">
        <div v-if="isLoading || errorFetching">
          <LoadingCard v-if="isLoading" />
          <ErrorCard v-if="errorFetching"  message="rejected dispense details not available"/>
        </div>
        <DispenserRejectedDetailsCard v-else :rejectDetails="rejectDetails"/>
      </template>
  </PageTemplate>
</template>

<script>
import PageTemplate from "../../components/Helpers/Pages/PageTemplate";
import BasicNav from "../../components/Helpers/Navs/BasicNav";
import BackButton from "../../components/Helpers/Buttons/BackButton";
import LoadingCard from "../../components/Helpers/Loading/LoadingCard";
import ErrorCard from "../../components/Helpers/Pages/ErrorCard";
import DispenserRejectedDetailsCard from "../../components/Cards/DispenserRejectedDetailCard";
import {mapActions, mapState} from "vuex";
export default {
  name: "DispenserLogRejectDetail",
  components: {ErrorCard, LoadingCard, BackButton, BasicNav, PageTemplate, DispenserRejectedDetailsCard},
  data() {
    return {
      isLoading: false,
      errorFetching: false,
      resetRejectedTitle: "Cash Dispenser - Rejected Dispenses Cleared",
    }
  },
  computed: {
    ...mapState("DispenseLogs", ["rejectDetails"]),
  },
  methods: {
    ...mapActions("DispenseLogs", ["getDispenserCashRejectResetDetail"]),

    goBack() {
      this.$router.go(-1);
    },
    async fetchData() {
      this.isLoading = true;
      const id = this.$route.params.id;
      try {
        await this.getDispenserCashRejectResetDetail(id);
      } catch (e){
        this.errorFetching = true;
      } finally {
        this.isLoading = false;
      }
    },

  },
  mounted() {
    this.fetchData()
  }
}
</script>