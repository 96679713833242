<template>
  <BasicCard>
    <template slot="body">
      <div class="w-400 m-auto p-3 text-center ">
        <font-awesome-icon class="text-orange text-center m-4" size="8x" :icon="['far', 'exclamation-circle']"  />
        <h2 class="text-orange text-3xl mb-4 uppercase text-center error-title">{{message}}</h2>
          <ul v-if="errorsExist">
            <li v-for="(error, index) in errors" class="text-xl text-red" :key="`${index}`">
              {{ error }}
            </li>
          </ul>
      </div>
    </template>
  </BasicCard>
</template>
<script>
import BasicCard from "../Cards/BasicCard";
export default {
  name: "ErrorCard",
  components: {BasicCard},
  computed: {
    errorsExist() {
      return this.errors && this.errors.length > 0;
    }
  },
  props: {
    message:{
      type: String,
      required: false,
      default: "Page Not Available"
    },
    errors: {
      type: Array,
      required: false,
    }
  }
}
</script>

<style scoped>

</style>