import currency from "currency.js";

export default {
    data() {
        return {
            MONEY_DENOMINATIONS: [
                {
                    label: "$1 Bills",
                    denomination: "ONE",
                    denominationType: "cash",
                    dispenser: true,
                    acceptor: true,
                    quantityMultiplier: 1
                },
                {
                    label: "$5 Bills",
                    denomination: "FIVE",
                    denominationType: "cash",
                    dispenser: true,
                    acceptor: true,
                    quantityMultiplier: 5
                },
                {
                    label: "$10 Bills",
                    denomination: "TEN",
                    denominationType: "cash",
                    dispenser: false,
                    acceptor: true,
                    quantityMultiplier: 10
                },
                {
                    label: "$20 Bills",
                    denomination: "TWENTY",
                    denominationType: "cash",
                    dispenser: false,
                    acceptor: true,
                    quantityMultiplier: 20
                },
                {
                    label: "$50 Bills",
                    denomination: "FIFTY",
                    denominationType: "cash",
                    dispenser: false,
                    acceptor: true,
                    quantityMultiplier: 50
                },
                {
                    label: "$100 Bills",
                    denomination: "HUNDRED",
                    denominationType: "cash",
                    dispenser: false,
                    acceptor: true,
                    quantityMultiplier: 100
                },
                {
                    label: "Pennies",
                    denomination: "PENNY",
                    denominationType: "coin",
                    dispenser: true,
                    acceptor: false,
                },
                {
                    label: "Nickels",
                    denomination: "NICKEL",
                    denominationType: "coin",
                    dispenser: true,
                    acceptor: false,
                },
                {
                    label: "Dimes",
                    denomination: "DIME",
                    denominationType: "coin",
                    dispenser: false,
                    acceptor: false,

                },
                {
                    label: "Quarters",
                    denomination: "QUARTER",
                    denominationType: "coin",
                    dispenser: true,
                    acceptor: false,
                },
                {
                    label: "Half Dollar",
                    denomination: "HALF",
                    denominationType: "coin",
                    dispenser: false,
                    acceptor: false,
                },
            ],
        };
    },
    computed: {
        largeOrangeText() {
            return "text-orange font-medium text-xl"
        },
        getDispenserDenominations() {
            return this.MONEY_DENOMINATIONS.filter(obj => {
                return obj.dispenser;
            })
        },
        getAcceptorDenominations() {
            return this.MONEY_DENOMINATIONS.filter(obj => {
                return obj.acceptor;
            })
        },
        getDispenserDenominationsCashOnly() {
            return this.MONEY_DENOMINATIONS.filter(obj => {
                return obj.dispenser && obj.denominationType === "cash" ;
            })
        },

    },
    methods: {
        currencyFormat(value) {
            return currency(value).format();
        },
    },
}